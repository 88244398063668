export const STORAGE_KEYS = {
    STANDARD_TAG: 'standard.tag',
    STANDARD_GIVEN_NAME: 'standard.givenName',
    STANDARD_FAMILY_NAME: 'standard.familyName',
    STANDARD_EMAIL_ADDRESS1: 'standard.emailAddress1',
    CALENDLY_FORM_SLUG: 'calendlyFormSlug',
};

export const STORAGE_STATE = {
    FORM_STATE: 'keap-hosting-form-state',
    CALENDLY_STATE: 'keap-hosting-calendly-state',
};

export const WARN_MESSAGES = {
    MISSING_FIELDS: 'Required fields are missing',
    SUBMISSION_ERROR: 'Error submitting',
};

export const DATA_ATTRIBUTES = {
    CALENDLY_URL: 'data-calendly-url',
    CALENDLY_TAG_APPLY: 'data-calendly-tag-apply',
    CALENDLY_FORM_SLUG: 'data-calendly-form-slug',
    CALENDLY_NAME_URL_PARAM: 'data-calendly-name-param',
    CALENDLY_EMAIL_URL_PARAM: 'data-calendly-email-param',
};
